import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RentRequestsService } from 'src/app/services/rent-requests.service';
import { RENT_REQUEST_STAGES, RENT_REQUEST_STATUS } from 'src/app/utils/constant';
import { Utils } from 'src/app/utils/utils';
import { MessageModalComponent } from '../../general/modal/message-modal/message-modal.component';
import { ConfirmModalComponent } from '../../matching/shared/confirm-modal/confirm-modal.component';
import { DateUtil } from 'src/app/utils/dateUtil';
import { ApiService } from 'src/app/services/api.service';
import { ListingService } from 'src/app/services/listing.service';
import { firstValueFrom } from 'rxjs';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { LayoutService } from 'src/app/services/layout.service';
const moment = require('moment');

@Component({
  selector: 'app-booking-request-detail',
  templateUrl: './booking-request-detail.component.html',
  styleUrls: ['./booking-request-detail.component.css'],
})
export class BookingRequestDetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private rentRequestsService: RentRequestsService,
    private utils: Utils,
    private router: Router,
    private loginService: LoginService,
    private modalService: NgbModal,
    public dateUtil: DateUtil,
    public apiService: ApiService,
    private listingService: ListingService,
    public layoutService: LayoutService
  ) { }

  bookingRequest: any = null as any;
  activeId: number = 1;
  roomsWithPrice: any[] = [];
  modalTitle: string = "";
  modalMessage: string = "";
  guestImage: string = "";

  flagEditDate: boolean = false;
  isDateValidToChange: boolean = true;
  switchingRooms: boolean = false;
  latestApartment: any = null as any;
  flagEditPrice: boolean = false;
  newPrice: string = '';
  totalAmountForEntireStay: number = 0;
  bookedRoomInfo: any = [];

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.rentRequestsService.getPendingRequestById(params['id']).subscribe(resp => {
        this.bookingRequest = resp;
        this.bookingRequest.newMoveInDate = this.bookingRequest.moveInDate;
        this.bookingRequest.newMoveOutDate = this.bookingRequest.moveOutDate;
        this.roomsWithPrice = this.getMonthlyRentRooms();
        this.getTotalAmountForEntireStay();
        this.loginService.setLoggedIn(true);
        this.newPrice = this.bookingRequest.price;
        this.bookedRoomInfo = this.getBookedRoomInfo();
        if (this.bookingRequest.userId.customImage) {
          this.rentRequestsService.getSecureDocument('user', this.bookingRequest.userId.customImage).subscribe(resp => {
            this.guestImage = resp.url;
          });
        }
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
    })
  }

  getBookedRoomInfo() {
    let res = [];
    for (let id of this.bookingRequest.roomIds) {
      for (let room of this.bookingRequest.apartment.rooms) {
          if (room._id == id) {
            res.push(room);
          }
      }
    }
    return res;
  }

  getImgHost() {
    return this.utils.getImageHost();
  }

  isPendingAdmin() {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.ADMIN &&
       this.bookingRequest.status == RENT_REQUEST_STATUS.PENDING)
  }

  isPendingHost() {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.HOST &&
      this.bookingRequest.status == RENT_REQUEST_STATUS.PENDING)
  }

  isDeclinedByAdmin() {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.ADMIN
      && this.bookingRequest.status == RENT_REQUEST_STATUS.DECLINED);
  }

  isDeclinedByHost() {
    return (this.bookingRequest.stage == RENT_REQUEST_STAGES.HOST
        && this.bookingRequest.status == RENT_REQUEST_STATUS.DECLINED);
  }

  isClosedBySystem(): boolean {
    return (this.bookingRequest.status == RENT_REQUEST_STATUS.CLOSED_BY_SYSTEM);
  }

  getMonthlyRentRooms() {
    let res = [];
    for (let roomId of this.bookingRequest.roomIds) {
      for (let i = 0; i < this.bookingRequest.apartment.rooms.length; ++i) {
        if (roomId == this.bookingRequest.apartment.rooms[i]._id) {
          res.push({
            index: i,
            price: this.bookingRequest.apartment.rooms[i].price
          })
        }
      }
    }
    return res;
  }


  isSamePrice(): boolean {
    if (this.roomsWithPrice.length == 0) return false;
    let price = this.roomsWithPrice[0];
    let res = true;
    for (let i = 0; i < this.roomsWithPrice.length && res; ++i) {
      res = res && (this.roomsWithPrice[i].price == price);
    }
    return res;
  }

  getTotalAmountForEntireStay() {
    let rooms = [];
    for (let room of this.bookingRequest.apartment.rooms) {
      if (this.bookingRequest.roomIds.includes(room._id)) {
        rooms.push(room);
      }
    }

    this.rentRequestsService.getTotalRentForEntireStay(this.bookingRequest._id).subscribe(resp => {
      let tmpSum = 0;
      for (const payout of resp) {
        tmpSum += payout.amountDue;
      }
      this.totalAmountForEntireStay = Math.round(tmpSum);
    });
  }

  getPurposeOfStay(): string {
    if (this.bookingRequest.purposeOfStay && this.bookingRequest.purposeOfStay.length > 0) return this.bookingRequest.purposeOfStay;
    return '/';
  }

  sendApprovementEmail() {
    const modalRef = this.modalService.open(MessageModalComponent);
    this.rentRequestsService.sendEmailToHost(this.bookingRequest?._id).subscribe(resp => {
      modalRef.componentInstance.title = "Success";
      modalRef.componentInstance.message = "Email Sent";
      // this.bookingRequest.status = 'DECLINED';
    }, (error) => {
      modalRef.componentInstance.title = "Error";
      if (!error.error) error = {error: error}
      modalRef.componentInstance.message = error.error;
    })
  }

  updatePendingRequest() {
    // create modal
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.closed.subscribe(() => {
      const msgModal = this.modalService.open(MessageModalComponent);
      let fieldsToBeUpdated: any = {};
      const newMoveInDate = moment(this.bookingRequest.moveInDate);
      const newMoveOutDate = moment(this.bookingRequest.moveOutDate);

      fieldsToBeUpdated.moveInDate = this.bookingRequest.newMoveInDate;
      fieldsToBeUpdated.moveOutDate = this.bookingRequest.newMoveOutDate;
      fieldsToBeUpdated.stayInDays = newMoveOutDate.diff(newMoveInDate, 'days');
      fieldsToBeUpdated.stayInMonths = newMoveOutDate.diff(newMoveInDate, 'months', true);
      fieldsToBeUpdated._id = this.bookingRequest._id;

      this.rentRequestsService.update(fieldsToBeUpdated).subscribe(resp => {
        this.bookingRequest.moveInDate = this.bookingRequest.newMoveInDate;
        this.bookingRequest.moveOutDate = this.bookingRequest.newMoveOutDate;
        this.bookingRequest.paymentData = resp.paymentData;
        this.flagEditDate = false;
        msgModal.componentInstance.title = "Success";
        msgModal.componentInstance.message = "Successful operation";
      }, (error) => {
        msgModal.componentInstance.title = "Error";
        if (!error.error) error = {error: error}
        msgModal.componentInstance.message = error.error;
      })
    })
    
  }

  updateBookingRequestPrice() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.closed.subscribe(() => {
      const compRef = this.modalService.open(MessageModalComponent);
      const newPrice = parseFloat(this.newPrice);
      if (!newPrice || newPrice === 0) {
        compRef.componentInstance.title = "Error";
        compRef.componentInstance.message = "Please input a valid price";
        return;
      }
      this.rentRequestsService.changeBookingRequestPrice(this.bookingRequest._id, newPrice).subscribe(resp => {
        this.flagEditPrice = false;
        this.bookingRequest.originalRent = parseFloat(this.newPrice);
        compRef.componentInstance.title = "Success";
        compRef.componentInstance.message = "Successful operation";
      }, ({error}) => {
        compRef.componentInstance.title = "Failed";
        if (!error.error) error = {error: error}
        compRef.componentInstance.message = error.error;
        this.flagEditPrice = false;
      })
    })
  }

  parseDate(dateString: string): Date {
    return new Date(dateString!);
  }

  changeMoveInDate(date: any): void {
    this.bookingRequest.newMoveInDate = date;
    this.checkValidForUpdating();
  }

  changeMoveOutDate(date: any): void {
    console.log(date);
    console.log(this.dateUtil.createDate(date));
    this.bookingRequest.newMoveOutDate = date;
    this.checkValidForUpdating();
  }

  downloadSecureDoc(category: 'poi' | 'identity' | 'pos' | 'poe' | 'otherDocument', item: any) {
    const entryDict: {
      [key: string]: string
    } = {
      "poi": "proofs-of-income",
      "identity": "identities",
      "pos": "proof-of-study",
      "poe": "proof-of-employment",
      "otherDocument": "others"
    }
    const filename = typeof item === 'string' ? item : item.filename;
    this.rentRequestsService.getSecureDocument(entryDict[category], filename).subscribe((resp) => {
      window.open(resp.url, '_blank');
    });
  }

  getBookingRequestDetailUrl(): string {
    return `https://${this.apiService.isProd() ? '' : 'devweb.'}hivenue.ca/en/rent-request-details/${this.bookingRequest._id}`;
  }

  decline() {
    const ref = this.modalService.open(ConfirmModalComponent);
    ref.closed.subscribe(() => {
      const modalRef = this.modalService.open(MessageModalComponent);
      this.rentRequestsService.decline({
        _id: this.bookingRequest._id,
        user: {
          _id: "admin"
        }
      }).subscribe(resp => {
        modalRef.componentInstance.title = "Success";
        modalRef.componentInstance.message = "The booking request has been declined";
        modalRef.componentInstance.clickEvent.subscribe((_:any) => {
          location.reload();
        })
      }, error => {
        modalRef.componentInstance.err = "Error";
        if (!error.error) error = {error: error}
        modalRef.componentInstance.message = error.error;
      })
    })
  }

  async checkValidForUpdating() {
    // get latest apartment
    const apartment = await firstValueFrom(this.listingService.getApartmentById(this.bookingRequest.apartment._id));
    const newMoveInDate = moment(this.bookingRequest.newMoveInDate);
    const newMoveOutDate = moment(this.bookingRequest.newMoveOutDate);
    if (newMoveOutDate.diff(newMoveInDate, 'months', true) >= Number(apartment.minimumStay)) {
      this.isDateValidToChange = true;
    } else {
      this.isDateValidToChange = false;
    }
  }

  async toggleSwitchRooms() {
    this.switchingRooms = !this.switchingRooms;
    this.latestApartment = await firstValueFrom(this.listingService.getApartmentById(this.bookingRequest.apartment._id))
    this.latestApartment.rooms.forEach((room: any) => {
      room.checked = this.bookingRequest.roomIds.includes(room._id);
      // disable rooms that have different min stay with current rooms
      if (room.checked) {
        room.enabled = true;
        this.latestApartment.rooms.forEach((anotherRoom: any) => {
          if (Number(anotherRoom.minimumStay) > Number(room.minimumStay)) {
            anotherRoom.enabled = false;
          }
        })
      }
    })
  }

  selectRoom(index: number) {
    if (!('checked' in this.latestApartment.rooms[index])) {
      this.latestApartment.rooms[index].checked = true;
    } else {
      this.latestApartment.rooms[index].checked = !this.latestApartment.rooms[index].checked; 
    }
  }

  switchRooms(): void {
    const ref = this.modalService.open(ConfirmModalComponent);
    ref.closed.subscribe(() => {
      const roomIds: any[] = [];
      const modalRef = this.modalService.open(MessageModalComponent);
      this.latestApartment.rooms.forEach((room: any) => {
        if ('checked' in room && room.checked) {
          roomIds.push(room._id)
        }
      })
      this.rentRequestsService.swtichRooms({
        id: this.bookingRequest._id,
        roomIds
      }).subscribe(
        resp => {
          this.rentRequestsService.sendEmailToAdmin(
            this.bookingRequest._id,
            this.bookingRequest.userId._id 
          ).subscribe(r => {
            modalRef.componentInstance.title = "success";
            modalRef.componentInstance.message = "Successful operation";
            modalRef.componentInstance.clickEvent.subscribe((_: any)=> {
              location.reload();
            })
          })
          
        },
        error => {
          modalRef.componentInstance.title = "error";
          if (!error.error) error = {error: error}
          modalRef.componentInstance.message = error.error;
        }
      )
    })
  }

}
