import { Keyword } from "../components/matching/matching-dashboard/ai-results/ai-results.types";

export interface MatchResponse {
    matches: MatchResults[];
    dev: BioDevData[];
}

export interface MatchResults {
    match_level: string;
    common_keywords_raw: Keyword[];
}

export interface BioDevData {
    clusters: number[];
    keywords: Keyword[];
}

export interface BioRetrieveMethod {
    id?: string;
    custom_bio?: string;
}

// To remove
export interface Prediction {
    clusters: number[];
    top_keywords: Keyword[];
}