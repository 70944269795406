import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, TitleStrategy } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DetailedUser } from 'src/app/models/user/detailedUser';
import { LayoutService } from 'src/app/services/layout.service';
import { ListingService } from 'src/app/services/listing.service';
import { LoginService } from 'src/app/services/login.service';
import { RentRequestsService } from 'src/app/services/rent-requests.service';
import { UserService } from 'src/app/services/user.service';
import { SentMessagesService } from 'src/app/services/sent-messages.service';
import { Constant } from 'src/app/utils/constant';
import { DateUtil } from 'src/app/utils/dateUtil';
import { DatePipe } from '@angular/common';
import { Utils } from 'src/app/utils/utils';
import { MessageModalComponent } from '../../general/modal/message-modal/message-modal.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  user: any = {} as any;
  userImage: string = '';
  activeId: number = 1;
  languageSpoken: { [id: string]: {
    name: string,
    nativeName: string
  }} = {};
  reservations: any[] = [];
  pendingRequests: any[] = [];
  apartments: any[] = [];
  sentMessages: any[] = [];
  // key: apartment ID
  // value: the rooms booked currently
  bookedRooms: {
    [id: string]: {
      moveInDate: Date,
      moveOutDate: Date,
      roomIds: string[]
    }[]
  } = {}

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private constant: Constant,
    private rentRequestsService: RentRequestsService,
    private listingService: ListingService,
    private sentMessagesService: SentMessagesService,
    private utils: Utils,
    private modalService: NgbModal,
    private loginService: LoginService,
    private router: Router,
    public dateUtil: DateUtil,
    private datePipe: DatePipe,
    public layoutService: LayoutService
  ) { 
    
  }
  
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.userService.getUserInfo(params['id']).subscribe(resp => {
        this.loginService.setLoggedIn(true);
        this.user = resp;
        this.getPendingRequests();
        this.getReservations();
        this.getApartments();
        this.getSentMessages();

        // Obtain the secure user image url
        if (this.user.customImage) {
          this.rentRequestsService.getSecureDocument('user', this.user.customImage).subscribe(resp => {
            this.userImage = resp.url;
          });
        }
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
    })

    this.constant.getSpokenLanguages().subscribe(resp => {
      this.languageSpoken = resp;
    })


  }

  calculateAge() {
    return this.userService.calculateAge(this.user.birthDate);
  }

  normalizeDate(date: any) {
    try {
      const rtn = this.dateUtil.createDate(date);
      if (!isNaN(rtn as any)) {
        return rtn;
      } else {
        return date;
      }
    } catch (e) {
      return date;
    }
  }

  getLanguage() {
    if (!this.user.language) return '/';
    if (this.user.language == 'fr') {
      return 'Français'
    }
    return 'English';
  }

  getLanguageSpoken() {
    let res = "";
    for (let i = 0; this.user.languageSpoken && i < this.user.languageSpoken.length; ++i) {
      res = res + this.languageSpoken[this.user.languageSpoken[i].toString()].name;
      if (i < this.user.languageSpoken.length - 1)
        res += ", "
    }
    if (res == "") 
      res = '/';
    return res;
  }

  getPendingRequests() {
    this.rentRequestsService.getPendingRequestsByUserId(this.user._id)
      .subscribe(resp => {
        this.loginService.setLoggedIn(true);
        this.pendingRequests = resp;
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
  }

  getReservations() {
    this.rentRequestsService.getReservationsByUserId(this.user._id, '')
      .subscribe(resp => {
        this.loginService.setLoggedIn(true);
        this.reservations = resp;
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
  }

  getApartments() {
    this.listingService.getApartmentsWithOwnerId(this.user._id)
      .subscribe(resp => {
        this.loginService.setLoggedIn(true);
        this.apartments = resp;
        for (let apartment of this.apartments) {
          this.getApprovedRooms(apartment._id);
        }
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
  }

  getApprovedRooms(id: string) {
    this.listingService.getApprovedRooms(id).subscribe(resp => {
      this.loginService.setLoggedIn(true);
      for (let history of resp) {
        if (this.utils.isCurrentDate(history.moveInDate, history.moveOutDate)) {
          if (!this.bookedRooms[id]) 
            this.bookedRooms[id] = [];
          this.bookedRooms[id].push({
            moveInDate: history.moveInDate,
            moveOutDate: history.moveOutDate,
            roomIds: []
          });
          for (let roomId of history.roomIds) {
            this.bookedRooms[id][this.bookedRooms[id].length - 1].roomIds.push(roomId);
          }
        }
      }
    }, error => {
      this.loginService.setLoggedIn(false);
      this.router.navigate(['/login']);
    })
  }

  getSentMessages()  {
    this.sentMessagesService.getSentMessagesByUserId(this.user._id)
      .subscribe(resp => {
        this.loginService.setLoggedIn(true);
        this.sentMessages = resp;
      }, error => {
        this.loginService.setLoggedIn(false);
        this.router.navigate(['/login']);
      })
  }

  getBookedRoomsCount(id: string) {
    let count = 0;
    for (let history of this.bookedRooms[id]) {
      count += history.roomIds.length;
    }
    return count;
  }

  open(id: string) {
    window.open(
      '/listing/' + id,
      '_blank'
    )
  }

  showDetails(sentMessage: any) {
    const msg =
      `Message sent by user ${sentMessage.senderId} on ${this.datePipe.transform(this.dateUtil.createDate(sentMessage.timestamp))} for apartment "${sentMessage.apartmentId.name}" (id: ${sentMessage.apartmentId._id}): \n` +
      `Name: ${sentMessage.formContent.name}\n` +
      `Phone: ${sentMessage.formContent.phone}\n` +
      `Email: ${sentMessage.formContent.email}\n` +
      `Move in month: ${sentMessage.formContent.moveInMonth}\n` +
      `Budget: ${sentMessage.formContent.budget}\n` +
      `Length of Stay: ${sentMessage.formContent.lengthOfStay}\n` +
      `Message: ${sentMessage.formContent.message}\n`;
    
    const msgModal = this.modalService.open(MessageModalComponent);
    msgModal.componentInstance.title = "Sent Message Details";
    msgModal.componentInstance.message = msg;
  }

  openModal(content: any, size = 'sm') {
    this.modalService.open(content, {size: size});
  }
}
