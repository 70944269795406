export interface KeywordCategory {
    _id: string;
    min: number;
    max: number;
    name: {
        fr: string;
        en: string;
    };
    subtitle: {
        fr: string;
        en: string;
    };
    keywords: Keyword[];
    showInMatchDetails: boolean;
}

export interface Keyword {
    _id: string,
    tag: string;
    cleaned: string;
    name: {
        fr: string;
        en: string;
    }
}