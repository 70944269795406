export interface Category {
    id: string;
    name: string;
    tokens: Token[];
    toggles?: CustomToggle[];
    additionalInfo?: string;
}

export interface Token {
    id: string;
    name: string;
}

export interface CategoryEvent {
    category: Category;
}

export interface TokenEvent {
    category: Category;
    token: Token;
}

export interface CustomToggle {
    id: string,
    name: string,
    value: boolean
}

export interface ToggleEvent {
    category: Category,
    toggleId: string,
    value: boolean
}