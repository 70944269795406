<ng-container *ngIf="bookingRequest">
    <div class="row">
        <div class="col-auto">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/booking-request">Booking Requests</a></li>
                    <li class="breadcrumb-item active text-secondary" aria-current="page">
                        {{ bookingRequest. _id }}
                    </li>
                </ol>
            </nav>
        </div>
    </div>
    <div class=" card py-4 basic-info container">
        <div class="row px-4">
            <div class="col-5" id="apartment-image">
                <a href="javascript:0;" >
                    <img 
                        src="{{getImgHost()}}apartment/{{bookingRequest.apartment.photos[0].source}}"
                        class="w-100" *ngIf="bookingRequest.apartment.photos.length > 0">
                </a>
            </div>
            <div class="col">
                <div class="row" id="apartment-info">
                    <div class="col-8 text-left">
                        <div class="row">
                            <h3>{{bookingRequest.apartment.name}}</h3>
                        </div>
                        <div class="row mt-2" *ngIf="layoutService.isLessThanSizeForFullDisplay()">
                            <h3 class="text-end" id="price">
                                {{ bookingRequest.paymentData.roomRent | currency: 'CAD' : 'symbol-narrow': '1.0-2'}}
                            </h3>
                        </div>
                        <div class="row mt-2">
                            <p>
                                <i class="bi bi-geo-alt"></i>
                                <a href="javascript:0;" class="text-decoration-none text-decoration-underline">
                                    {{ bookingRequest.apartment.adress.adress }}
                                </a>
                            </p>
                        </div>
                        <div class="row mt-2">
                            <div class="col-auto">
                                
                                <span class="badge bg-light fw-normal text-dark rounded-pill fs-14" *ngIf="!bookingRequest.apartment.wholeApartment">
                                    <ng-container *ngIf="bookingRequest.apartment.rooms.length == 1">
                                        1 Private Room
                                    </ng-container>
                                    <ng-container *ngIf="bookingRequest.apartment.rooms.length > 1">
                                        {{ bookingRequest.apartment.rooms.length }} Private Rooms
                                    </ng-container>
                                </span>
                                <span class="badge fw-normal bg-light text-dark rounded-pill fs-14" *ngIf="bookingRequest.apartment.wholeApartment">
                                    Entire Place
                                </span>
                          </div>                         
                                <ng-container *ngIf="!flagEditDate">
                                    <div class="col">
                                        <span class="badge fw-normal bg-light text-dark rounded-pill fs-14">
                                            Move-in/Move-out: 
                                            <span class="fw-bold">
                                                {{ dateUtil.createDate(bookingRequest.moveInDate) | date: 'mediumDate' }} - {{ dateUtil.createDate(bookingRequest.moveOutDate) | date: 'mediumDate'}}
                                            </span>
                                        </span>
                                        <i class="bi bi-pencil-square clickable" (click)="flagEditDate=true"></i>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="flagEditDate">
                                    <div class="row mt-2">
                                        <div class="col">
                                            Move-in
                                        </div>
                                        <div class="col">
                                            Move-out
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <input type="date" [ngModel]="dateUtil.createDate(bookingRequest.newMoveInDate) | date:'yyyy-MM-dd'" (ngModelChange)="changeMoveInDate($event)">
                                        </div> 
                                        <div class="col">
                                            <input type="date" [ngModel]="dateUtil.createDate(bookingRequest.newMoveOutDate) | date: 'yyyy-MM-dd'" (ngModelChange)="changeMoveOutDate($event)"> 
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <button (click)="updatePendingRequest()" [disabled]="!isDateValidToChange">confirm</button>
                                    </div>
                                </ng-container>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row text-end">
                            <span class="fs-14 text-secondary" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                Total to Pay
                            </span>
                        </div>
                        <div class="row mt-2" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                            <h3 class="text-end" id="price">
                                {{ totalAmountForEntireStay | currency: 'CAD' : 'symbol-narrow': '1.0-2'}}
                            </h3>
                        </div>
                        <div class="row mt-2">
                            <div class="col text-end fs-14" id="book-date">
                                Booked {{ bookingRequest.createdAt | date: 'mediumDate'}}
                            </div>
                            
                        </div>
                        <div class="row justify-content-end mt-2" id="status">
                            <div class="col-auto">
                                <span class="badge text-bg-light rounded-pill fs-14 fw-normal" *ngIf="isPendingAdmin()">Pending Admin</span>
                                <span class="badge text-bg-secondary rounded-pill fs-14 fw-normal" *ngIf="isPendingHost()">Pending Host</span>
                                <span class="badge text-bg-danger bg-opacity-75 rounded-pill fs-14 fw-normal" *ngIf="isDeclinedByAdmin()">Cancelled by Admin</span>
                                <span class="badge text-bg-danger bg-opacity-75 rounded-pill fs-14 fw-normal" *ngIf="isDeclinedByHost()">Cancelled by Host</span>
                                <span class="badge text-bg-warning bg-opacity-75 fw-bold rounded-pill" *ngIf="isClosedBySystem()">Closed by System</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="row mt-2 py-2">
                    <div class="col-auto">
                        <a class="btn bg-light border-dark" [href]="getBookingRequestDetailUrl()" target="_blank">
                            View Request
                        </a>
                    </div>
                    <div class="col-auto">
                        <a [routerLink]="['/listing', bookingRequest.apartment._id]" target="_blank" class="btn btn-light border-dark">
                            Listing Detail
                        </a>
                    </div>
                    <div class="col-auto">
                        <a class="btn btn-light border-dark" (click)="sendApprovementEmail()">
                            Resend Email
                        </a>
                    </div>
                    <div class="col-auto">
                        <a class="btn btn-danger" (click)="decline()" *ngIf="bookingRequest.status === 'PENDING'">
                            Decline
                        </a>
                        <a class="btn btn-danger disabled" *ngIf="bookingRequest.status !== 'PENDING' ">
                            Decline
                        </a>
                    </div>
                    <div class="col-auto">
                        <a 
                            class="btn btn-light border-dark" 
                            (click)="toggleSwitchRooms()" 
                            *ngIf="!bookingRequest.apartment.wholeApartment"
                        >Switch Room</a>
                    </div>
                </div>

                <div class="row mt-2 py-2" *ngIf="switchingRooms">
                    <p *ngIf="latestApartment.updatedAt != bookingRequest.updatedAt" class="text-red">
                        Note: This apartment has been updated since book date
                    </p>
                    <div *ngFor="let room of latestApartment.rooms; let i=index">
                        <input 
                            type="radio" 
                            name="{{room._id}}" 
                            id="{{room.description}}" 
                            [checked]="room.checked"
                            [disabled]="!room.enabled"
                            (click)="selectRoom(i)"
                        > 
                        <label for="{{room.description}}" *ngIf="room.enabled">
                            {{room.description}}
                        </label>
                        <label for="{{room.description}}" *ngIf="!room.enabled" class="text-secondary">
                            {{room.description}}
                        </label>
                    </div>
                    <div class="row mt-2">
                        <div class="col-auto">
                            <a class="btn btn-success" (click)="switchRooms()">Change</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row mt-2 px-4 py-2">
            <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeId">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Guest Details</a>
    
                    <ng-template ngbNavContent>
                        <div class="container">
                            <div class="row mt-2">
                                <div class="col-auto">
                                    <img *ngIf="bookingRequest?.userId?.customImage" src="{{guestImage}}" alt="" class="rounded-circle">
                                    <ngx-avatar *ngIf="!bookingRequest?.userId?.customImage" size="75" [textSizeRatio]="3" name="{{ bookingRequest?.userId?.first_name }} {{ bookingRequest?.userId?.last_name }}"></ngx-avatar>
                                </div>
                                <div class="col">
                                    <div class="row mt-2" *ngIf="!layoutService.isLessThanSizeForFullDisplay()">
                                        <div class="col"><span class="text-secondary fs-14">Guest Name</span></div>
                                        <div class="col"><span class="text-secondary fs-14">Guest ID</span></div>
                                        <div class="col"><span class="text-secondary fs-14">Purpose of Stay</span></div>
                                        <div class="col"><span class="text-secondary fs-14">Monthly Rent</span></div>
                                    </div>
                                    <div class="row mt-2" >
                                        <div class="col">
                                            <a href="javascript:0" class="text-decoration-none text-decoration-underline fw-bold fs-14">
                                                {{ bookingRequest?.userId?.first_name}} {{ bookingRequest?.userId?.last_name}}
                                            </a>
                                            
                                        </div>
                                        <div class="col"><span class="fs-14 fw-bold">
                                            {{ bookingRequest?.userId?._id }}
                                        </span></div>
                                        <div class="col"><span class="fs-14 fw-bold">
                                            {{ getPurposeOfStay() }}
                                        </span></div>
                                        <div class="col" *ngIf="!flagEditPrice">
                                            <span class="fs-14 fw-bold">
                                                {{ bookingRequest.originalRent | currency: 'CAD' : 'symbol-narrow': '1.0-2'}} 
                                                <i class="bi bi-pencil-square clickable" (click)="flagEditPrice=true"></i>
                                            </span>
                                        </div>
                                        <div class="col" *ngIf="flagEditPrice">
                                            <input type="text" [(ngModel)]="newPrice"> 
                                            <button (click)="updateBookingRequestPrice()">confirm</button>
                                        </div>
                                    </div>
                                    <div class="row mt-5">
                                        <div class="row">
                                            <div class="col-3">
                                                <span class="text-secondary fs-14">
                                                    Documents
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col-3" id="documents">
                                                <ngb-accordion class="justify-content-center">
                                                    <ngb-panel *ngIf="bookingRequest.poi || (bookingRequest.userId.poi && bookingRequest.userId.poi.length > 0)">
                                                        <ng-template ngbPanelTitle>
                                                            PoI 
                                                        </ng-template>
                                                        <ng-template ngbPanelContent>
                                                            <div class="row" *ngFor="let poi of [bookingRequest.poi].concat(bookingRequest.userId.poi)">
                                                                <a href="javascript:0;" *ngIf="poi" (click)="downloadSecureDoc('poi', poi)">
                                                                    {{ poi.filename ? (poi.date | date: 'mediumDate') : "Legacy" }}
                                                                </a>
                                                            </div>
                                                        </ng-template>
                                                    </ngb-panel>

                                                    <ngb-panel *ngIf="bookingRequest.identity || (bookingRequest.userId.identity && bookingRequest.userId.identity.length > 0)">
                                                        <ng-template ngbPanelTitle>
                                                            Identity
                                                        </ng-template>
                                                        <ng-template ngbPanelContent>
                                                            <div class="row" *ngFor="let identity of [bookingRequest.identity].concat(bookingRequest.userId.identity)">
                                                                <a href="javascript:0;" *ngIf="identity" (click)="downloadSecureDoc('identity', identity)">
                                                                    {{ identity.filename ? (identity.date | date: 'meidumDate') : "Legacy" }}
                                                                </a>
                                                            </div>
                                                        </ng-template>
                                                    </ngb-panel>

                                                    <ngb-panel *ngIf="bookingRequest.userId.pos && bookingRequest.userId.pos.length > 0">
                                                        <ng-template ngbPanelTitle>
                                                            PoS
                                                        </ng-template>
                                                        <ng-template ngbPanelContent>
                                                            <div class="row" *ngFor="let pos of bookingRequest.userId.pos">
                                                                <a href="javascript:0;" *ngIf="pos" (click)="downloadSecureDoc('pos', pos)">
                                                                    {{ pos.filename ? (pos.date | date: 'mediumDate') : "Legacy" }}
                                                                </a>
                                                            </div>
                                                        </ng-template>
                                                    </ngb-panel>

                                                    <ngb-panel *ngIf="bookingRequest.userId.poe && bookingRequest.userId.poe.length > 0">
                                                        <ng-template ngbPanelTitle>
                                                            PoE
                                                        </ng-template>
                                                        <ng-template ngbPanelContent>
                                                            <div class="row" *ngFor="let poe of bookingRequest.userId.poe">
                                                                <a href="javascript:0;" *ngIf="poe" (click)="downloadSecureDoc('poe', poe)">
                                                                    {{ poe.filename ? (poe.date | date: 'mediumDate') : "Legacy" }}
                                                                </a>
                                                            </div>
                                                        </ng-template>
                                                    </ngb-panel>

                                                    <ngb-panel *ngIf="bookingRequest.otherDocument || (bookingRequest.userId.otherDocument && bookingRequest.userId.otherDocument.length > 0)">
                                                        <ng-template ngbPanelTitle>
                                                            Other Document
                                                        </ng-template>
                                                        <ng-template ngbPanelContent>
                                                            <div class="row" *ngFor="let otherDocument of [bookingRequest.otherDocument].concat(bookingRequest.userId.otherDocument)">
                                                                <a href="javascript:0;" *ngIf="otherDocument" (click)="downloadSecureDoc('otherDocument', otherDocument)">
                                                                    {{ otherDocument.filename ? (otherDocument.date | date: 'mediumDate') : "Legacy" }}
                                                                </a>
                                                            </div>
                                                        </ng-template>
                                                    </ngb-panel>
                                                </ngb-accordion> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="row">
                                            <div class="col-auto">
                                                <span class="text-secondary fs-14">
                                                    Message
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row col-8 mt-2">
                                            <p>
                                                {{ bookingRequest.message}}
                                            </p>
    
                                            
                                        </div>
                                        <ng-template #messageContent let-modal>
                                            <div class="modal-header">
                                                <h4 class="modal-title">Message</h4>
                                                <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                                            </div>
                                            <div class="modal-body">
                                                <p class="text-secondary">
                                                    {{ bookingRequest.message }}
                                                </p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
                                            </div>
                                        </ng-template>
                                        
                                    </div>
                                    <div class="matching-info mt-4">
                                        <div class="row">
                                            <div class="col-auto">
                                                <span class="text-secondary fs-14">
                                                    About {{ bookingRequest?.userId?.first_name }}
                                                </span>
                                            </div>
                                        </div> 
                                        <div class="row mt-3">
                                            <div class="row">
                                                <div class="col fw-bold fs-14">My Personality</div>
                                                <div class="col fw-bold fs-14">My Lifestyle</div>
                                                <div class="col fw-bold fs-14">My Interests</div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <div class="row row-cols-3">
                                                        <span *ngIf="!bookingRequest?.userId?.matching_info || bookingRequest?.userId?.matching_info.personality.length == 0" class="fs-14">
                                                            /
                                                        </span>
                                                        <div class="col" *ngFor="let personality of bookingRequest?.userId?.matching_info.personality">
                                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                                {{ personality.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="row row-cols-3">
                                                        <span class="fs-14" *ngIf="!bookingRequest?.userId?.matching_info || bookingRequest?.userId?.matching_info.lifestyle.length == 0">
                                                            /
                                                        </span>
                                                        <div class="col" *ngFor="let lifestyle of bookingRequest?.userId?.matching_info.lifestyle">
                                                            
                                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                                {{ lifestyle.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="row row-cols-3">
                                                        <span class="fs-14" *ngIf="!bookingRequest?.userId?.matching_info || bookingRequest?.userId?.matching_info.interests.length == 0">
                                                            /
                                                        </span>
                                                        <div class="col" *ngFor="let interest of bookingRequest?.userId?.matching_info.interests">
                                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                                {{ interest.name }}
                                                            </span>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="row">
                                                <div class="col fw-bold fs-14">Music</div>
                                                <div class="col fw-bold fs-14">Sports</div>
                                                <div class="col fw-bold fs-14">Cinema</div>
                                            </div>
                                            <div class="row mt-2">
                                                <div class="col">
                                                    <div class="row row-cols-3">
                                                        <span class="fs-14" *ngIf="!bookingRequest?.userId?.matching_info || bookingRequest?.userId?.matching_info.music.length == 0">
                                                            /
                                                        </span>
                                                        <div class="col" *ngFor="let music of bookingRequest?.userId?.matching_info.music">
                                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                                {{ music.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="row row-cols-3">
                                                        <span class="fs-14" *ngIf="!bookingRequest?.userId?.matching_info || bookingRequest?.userId?.matching_info.sports.length == 0">
                                                            /
                                                        </span>
                                                        <div class="col" *ngFor="let sport of bookingRequest?.userId?.matching_info.sports">
                                                            <span class="badge rounded-pill text-bg-light px-3 py-2 border border-dark-subtle fs-14">
                                                                {{ sport.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <div class="row row-cols-3">
                                                        <span class="fs-14" *ngIf="!bookingRequest?.userId?.matching_info || bookingRequest?.userId?.matching_info.movies.length == 0">
                                                            /
                                                        </span>
                                                        <div class="col" *ngFor="let movie of bookingRequest?.userId?.matching_info.movies">
                                                            <span class="badge rounded-pill px-3 py-2 text-bg-light border border-dark-subtle fs-14">
                                                                {{ movie.name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <a ngbNavLink>Request Information</a>
                    
                    <ng-template ngbNavContent>
                        <h5 class="mt-2 text-secondary">Booked Rooms {{ bookedRoomInfo.length }} / {{ bookingRequest.apartment.rooms.length }}</h5>
                        <ngb-accordion>
                            <ngb-panel *ngFor="let room of bookedRoomInfo">
                                <ng-template ngbPanelTitle>
                                    {{ room.description }}
                                </ng-template>
                                <ng-template ngbPanelContent></ng-template>
                            </ngb-panel>
                          </ngb-accordion>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3">
                    <a ngbNavLink>Payment Method</a>
    
                    <ng-template ngbNavContent>
                        <div class="container">
                            <div class="row">
                                <div class="col-10">
                                    <div class="row mt-4">
                                        <div class="col"><span class="text-secondary fs-14">Number</span></div>
                                        <div class="col"><span class="text-secondary fs-14">Expires</span></div>
                                        <div class="col"><span class="text-secondary fs-14">Type</span></div>
                                        <div class="col"><span class="text-secondary fs-14">Issuer</span></div>
                                        <div class="col"><span class="text-secondary fs-14">Origin</span></div>
                                        <div class="col"><span class="text-secondary fs-14">CVC Check</span></div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            ●●●●
                                        </div>
                                        <div class="col"></div>
                                        <div class="col"></div>
                                        <div class="col"></div>
                                        <div class="col"></div>
                                        <div class="col"></div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <a ngbNavLink>Contact</a>
                    <ng-template ngbNavContent>
                        <div class="container">
                            <div class="row">
                                <div class="col-auto">
                                    <img *ngIf="bookingRequest?.userId?.customImage" src="{{guestImage}}" alt="" class="rounded-circle">
                                    <ngx-avatar *ngIf="!bookingRequest?.userId?.customImage" size="75" [textSizeRatio]="3" name="{{ bookingRequest?.userId?.first_name }} {{ bookingRequest?.userId?.last_name }}"></ngx-avatar>
                                </div>
                                <div class="col align-items-center py-4">
                                    <div class="row align-self-center">
                                        <div class="col-3 text-secondary fs-14">
                                            Full Name
                                        </div>
                                        <div class="col-3 text-secondary fs-14">
                                            Phone Number
                                        </div>
                                        <div class="col-6 text-secondary text-start fs-14">
                                            Email 
                                        </div> 
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <a href="javascript:;" class="text-decoration-none text-decoration-underline fw-bold fs-14">
                                                {{ bookingRequest?.userId?.first_name + " " + bookingRequest?.userId?.last_name }}
                                            </a>
                                        </div>
                                        <div class="col-3 fw-bold fs-14">
                                            {{ bookingRequest?.userId?.phone }}
                                        </div>
                                        <div class="col-6 fw-bold fs-14">
                                            {{ bookingRequest?.userId?.email }}
                                        </div>
                                    </div>
                                    <div class="row align-self-center mt-4">
                                        <div class="col-3 text-secondary fs-14">
                                            Emergency Phone
                                        </div>
                                        <div class="col-3 text-secondary fs-14">
                                            Emergency Email
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <a href="javascript:;" class="text-decoration-none text-decoration-underline fw-bold fs-14">
                                                {{ bookingRequest?.emergencyPhone }}
                                            </a>
                                        </div>  
                                        <div class="col-3 fw-bold fs-14">
                                            {{ bookingRequest?.emergencyEmail }}
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                       
                    </ng-template>
                </li>
                <li [ngbNavItem]="4">
                    <a ngbNavLink>Agreement</a>
                    <ng-template ngbNavContent>
                        <div class="container">
                            <div class="row" [innerHTML]="bookingRequest?.agreement">
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>
    
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>

        
    </div>
</ng-container>

