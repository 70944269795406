import { Apartment } from "../apartment/apartment";
import { Reservation } from "../rent-requests/reservation";
import { DetailedUser } from "../user/detailedUser";

export interface Match {
    user: DetailedUser,
    matchingId: string,
    matchReasonRaw: MatchReasonRaw[],
    matchReason: string[],
    matchLevel: string,
}

export interface HistoryResponse {
    itemCount: number;
    pageCount: number;
    pageSize: number;
    pageNumber: number;
    firstItem: number;
    lastItem: number;
    history: MatchingHistory[];
}

export interface MatchingHistory extends Match {
    _id: string,
    apartment: Apartment,
    rentRequest: Reservation,
    individualMatches: Match[],
    neighborhoodMatchReasonRaw: MatchReasonRaw[],
    neighborhoodMatchReason: string[],
    neighborhoodMatchLevel: string,
    created: Date,
}

interface MatchReasonRaw {
    keyword: string,
    weight: number
}